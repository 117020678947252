import axios from '@/plugins/http/axios';
import qs from 'qs';
import { ApiResponse, ProcessCompletionDetails } from '@/shared/types/generic';
import {
  NewSurveyRequest,
  SurveyQuestion,
  SurveyData,
  EditSurveyRequest,
  LoadSurveysResponse,
  LoadSurveysRequest,
} from '@/shared/types/surveys';

export default {
  getSurveys(
    request: LoadSurveysRequest,
  ): Promise<ApiResponse<LoadSurveysResponse>> {
    return axios.get('/surveys', {
      params: {
        page: request.page,
        size: request.size,
        query: request.query,
        order_by: request.sortBy ? request.orderBy : undefined,
        sort_by: request.sortBy,
        status: request.status,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  },

  createSurvey(newSurvey: NewSurveyRequest): Promise<void> {
    return axios.post('/surveys', newSurvey);
  },

  getSurveyAudience(): Promise<{ numberOfUsers: number }> {
    return axios.get('/surveys/users');
  },

  getSurveyQuestions(
    reference: string,
  ): Promise<{ surveyQuestions: SurveyData }> {
    return axios.get(`/surveys/${reference}`);
  },

  getSurveyCompletionDetails(
    surveyReference: string,
  ): Promise<ProcessCompletionDetails> {
    return axios.get(`/survey-completions/${surveyReference}`);
  },

  getDefaultQuestions(): Promise<ApiResponse<SurveyQuestion[]>> {
    return axios.get('/surveys/default-questions');
  },

  updatePopupFlag(popupFlag: boolean): Promise<void> {
    return axios.put(`/surveys/popup/${popupFlag}`);
  },

  editSurvey(editSurvey: EditSurveyRequest): Promise<void> {
    return axios.post(`/surveys/${editSurvey.reference}`, editSurvey);
  },

  removeSurvey(surveyReference: string): Promise<void> {
    return axios.delete(`/surveys/${surveyReference}`);
  },
};
