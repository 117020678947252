import { defineStore } from 'pinia';
import {
  CategorizedMetrics,
  Category,
  ChartMetricsResponse,
  EditMetric,
  LastSearchMetricsResponse,
  Metric,
  CreateCategoryRequestParameters,
  EditCategoryRequestBody,
  CreateMetricRequestBody,
  GetChartPointsRequestBody,
  UnitOfMeasure,
  MetricCategoryType,
  SimplifiedCategory,
  LoadMetricRequest,
  MetricStatusStaticReference,
} from '@/shared/types/metrics';
import metricsService from '@/shared/services/metricsService/metricsService';
import { GetRequestParameters } from '@/shared/types/requests';
import { ChallengeAudienceStaticReference } from '@/shared/types/challenges';

export default defineStore({
  id: 'metrics',

  state: () => ({
    metrics: [] as Metric[],
    categories: [] as Category[],
    unitsMeasure: [] as UnitOfMeasure[],
    paginatedCategories: [] as Category[],
    lastSearch: {} as LastSearchMetricsResponse,
    categorizedMetrics: {} as CategorizedMetrics,
    metricTypes: [] as MetricCategoryType[],
    categoryTypes: [] as MetricCategoryType[],
    categoriesRequestParams: [] as Category[],
    chartPoints: {} as ChartMetricsResponse,
    simplifiedCategories: [] as SimplifiedCategory[],
    loadMetricRequest: {} as LoadMetricRequest,
    counters: {
      numberOfMetrics: 0,
      numberOfFilteredMetrics: 0,
      numberOfCategories: 0,
    },
  }),

  getters: {
    hasMetricsActive: (state) =>
      state.metrics.some(
        (metric) =>
          metric.status.staticReference === MetricStatusStaticReference.ACTIVE,
      ),
  },

  actions: {
    async loadMetrics() {
      try {
        const response = await metricsService.getMetrics(
          this.loadMetricRequest,
        );
        this.metrics = response.results.metrics;
        this.counters.numberOfFilteredMetrics =
          response.results.totalFilteredMetrics;
      } catch (error) {
        this.metrics = [];
      }
    },

    async loadCounters(): Promise<void> {
      const { numberOfMetrics, numberOfCategories } =
        await metricsService.getCounters();
      this.counters.numberOfMetrics = numberOfMetrics;
      this.counters.numberOfCategories = numberOfCategories;
    },

    removeMetric(reference: string) {
      return metricsService.removeMetric(reference);
    },

    removeCategory(reference: string) {
      return metricsService.removeCategory(reference);
    },

    validateMetric(reference: string) {
      return metricsService.validateMetric(reference);
    },

    async loadCategories() {
      try {
        this.categories = (
          await metricsService.getCategories()
        ).results.categories;
      } catch (e) {
        this.categories = [];
      }
    },

    async getUnitOfMeasure(
      categoryReference?: string,
      metricsReferences?: string[],
    ) {
      try {
        this.unitsMeasure = (
          await metricsService.getUnitOfMeasure(
            categoryReference,
            metricsReferences,
          )
        ).results.unitsMeasure;
      } catch (e) {
        this.unitsMeasure = [];
      }
    },

    async getCategories(
      getCategoriesRequestParameters: GetRequestParameters = {},
    ): Promise<void> {
      try {
        this.categoriesRequestParams = (
          await metricsService.getCategories(getCategoriesRequestParameters)
        ).results.categories;
      } catch (e) {
        this.categoriesRequestParams = [];
      }
    },

    async editMetric(metric: EditMetric, metricReference: string) {
      const response = await metricsService.editMetric(metric, metricReference);

      await this.loadMetrics();

      return response;
    },

    async getLastSearch() {
      try {
        this.lastSearch = await metricsService.getLastSearch();

        this.chartPoints.chartPoints =
          this.lastSearch.chartData.histogramPoints;
      } catch (e) {
        this.lastSearch = {} as LastSearchMetricsResponse;
      }
    },

    async getCategorizedMetrics(
      isUsedInChallenge?: boolean,
      isActiveMetric?: boolean,
      isSafetyFeature?: boolean,
      audienceStaticReference?: ChallengeAudienceStaticReference,
      isMultipleSites?: boolean,
    ): Promise<void> {
      try {
        this.categorizedMetrics = await metricsService.getCategorizedMetrics(
          isUsedInChallenge,
          isActiveMetric,
          isSafetyFeature,
          audienceStaticReference,
          isMultipleSites,
        );
      } catch (e) {
        this.categorizedMetrics = {} as CategorizedMetrics;
      }
    },

    async createCategory(
      newCategory: CreateCategoryRequestParameters,
    ): Promise<void> {
      return metricsService.createCategory(newCategory);
    },

    async editCategory(
      reference: Category['reference'],
      requestBody: EditCategoryRequestBody,
    ): Promise<void> {
      return metricsService.editCategory(reference, requestBody);
    },

    async associateMetricsToCategory(
      metricReferences: Metric['reference'][],
      categoryReference?: Category['reference'],
    ): Promise<void> {
      return metricsService.associateMetricsToCategory(
        metricReferences,
        categoryReference,
      );
    },

    async loadMetricTypes(): Promise<void> {
      try {
        this.metricTypes = (
          await metricsService.getMetricTypes()
        ).results.metricTypes;
      } catch (e) {
        this.metricTypes = [];
      }
    },

    createMetric(newMetric: CreateMetricRequestBody) {
      return metricsService.createMetric(newMetric);
    },

    async loadChartPoints(
      requestBody: GetChartPointsRequestBody,
    ): Promise<void> {
      try {
        this.chartPoints = await metricsService.getChartPoints(requestBody);
      } catch {
        this.chartPoints = {} as ChartMetricsResponse;
      }
    },

    async loadSimplifiedCategories(
      isSafetyFeature?: boolean,
      categoryTypeReference?: string,
    ): Promise<void> {
      try {
        this.simplifiedCategories = (
          await metricsService.getSimplifiedCategories(
            isSafetyFeature,
            categoryTypeReference,
          )
        ).results.categories;
      } catch {
        this.simplifiedCategories = [];
      }
    },

    async loadCategoryTypes(): Promise<void> {
      try {
        this.categoryTypes = (
          await metricsService.getCategoryTypes()
        ).results.categoryTypes;
      } catch (e) {
        this.categoryTypes = [];
      }
    },

    setLoadMetricRequest(request: LoadMetricRequest): void {
      this.loadMetricRequest = request;
    },
  },
});
