import { defineStore } from 'pinia';
import {
  ChangeSiteRequest,
  ChangeTeamRequest,
  CreateUserRequestBody,
  EditTeamRequest,
  GetAllValidOperatorsResponse,
  LoadManagersRequest,
  LoadUsersRequest,
  IManager,
  NewTeam,
  NuvoUserRow,
  Operator,
  RequestUsersTimezones,
  Team,
  IUser,
  UserError,
  UserType,
  UsersSites,
  EditUserRequest,
  ChangeRoleLevelBulkRequest,
  ManagerDetailTeamsDetail,
  ManagerTeamsParticipantsDetail,
  ManagedItemsResponse,
} from '@/shared/types/user';
import userService from '@/shared/services/userService/userService';
import { AxiosResponse } from 'axios';
import {
  GetRequestParameters,
  PaginationParameters,
} from '@/shared/types/requests';
import { INFINITE_SCROLL_MODAL_PAGE_SIZE } from '@/shared/constants/variables';
import { Image } from '@/shared/types/generic';

interface State {
  users: IUser[];
  managers: IManager[];
  simplifiedOperators: Operator[];
  teams: Team[];
  simplifiedTeams: Team[];
  paginatedTeams: Team[];
  validationErrors: UserError[];
  userEmail: string;
  otp?: string;
  operators: Operator[];
  showTeamModal: boolean;
  userTypes: UserType[];
  ongoingChallengeNumber: number;
  groupedOperators?: GetAllValidOperatorsResponse;
  images: Image[];
  onGoingUserChallenges: number;
  onGoingUserSurveys: number;
  hasUsersDifferentTimezone: boolean;
  usersSites: UsersSites[];
  isLoadingUsersSites: boolean;
  loadUsersRequest: LoadUsersRequest;
  loadManagersRequest: LoadManagersRequest;
  managerTeamsDetail: ManagerDetailTeamsDetail;
  managerTeamProfile: ManagerDetailTeamsDetail;
  managerSiteProfile: ManagerDetailTeamsDetail;
  managerTeamsDetailPaginated: ManagerTeamsParticipantsDetail[];
  managerSitesDetailPaginated: ManagerTeamsParticipantsDetail[];
  counters: {
    numberOfPlayers: number;
    numberOfUsersFiltered: number;
    numberOfManagers: number;
    numberOfManagersFiltered: number;
    numberOfTeams: number;
  };
}

export default defineStore({
  id: 'user',

  state: (): State => ({
    users: [] as IUser[],
    managers: [] as IManager[],
    simplifiedOperators: [] as Operator[],
    teams: [] as Team[],
    simplifiedTeams: [] as Team[],
    paginatedTeams: [] as Team[],
    validationErrors: [],
    userEmail: '',
    otp: undefined,
    operators: [],
    showTeamModal: false,
    userTypes: [UserType.MANAGER, UserType.OPERATOR] as UserType[],
    ongoingChallengeNumber: 0,
    groupedOperators: undefined,
    images: [],
    onGoingUserChallenges: 0,
    onGoingUserSurveys: 0,
    hasUsersDifferentTimezone: false,
    usersSites: [],
    isLoadingUsersSites: false,
    loadUsersRequest: {} as LoadUsersRequest,
    loadManagersRequest: {} as LoadManagersRequest,
    managerTeamsDetail: {} as ManagerDetailTeamsDetail,
    managerSiteProfile: {} as ManagerDetailTeamsDetail,
    managerTeamProfile: {} as ManagerDetailTeamsDetail,
    managerTeamsDetailPaginated: [] as ManagerTeamsParticipantsDetail[],
    managerSitesDetailPaginated: [] as ManagerTeamsParticipantsDetail[],
    counters: {
      numberOfPlayers: 0,
      numberOfUsersFiltered: 0,
      numberOfManagers: 0,
      numberOfManagersFiltered: 0,
      numberOfTeams: 0,
    },
  }),

  actions: {
    setShowTeamModal() {
      this.showTeamModal = !this.showTeamModal;
    },

    async loadUsers() {
      try {
        const response = await userService.getUsers(this.loadUsersRequest);
        this.users = response.results.users;
        this.counters.numberOfUsersFiltered =
          response.results.numberOfUsersFiltered;
      } catch (error) {
        this.users = [];
      }
    },

    async loadTeams(
      getTeamsRequestParameters: GetRequestParameters = {},
    ): Promise<void> {
      try {
        const response = await userService.getTeams(getTeamsRequestParameters);
        this.teams = response.results.team;
      } catch (error) {
        this.teams = [];
      }
    },

    async loadManagers(): Promise<void> {
      try {
        const response = await userService.getManagers(
          this.loadManagersRequest,
        );
        this.managers = response.results.managers;
        this.counters.numberOfUsersFiltered =
          response.results.numberOfManagersFiltered;
      } catch (error) {
        this.managers = [];
      }
    },

    async getTeams(
      getTeamsRequestParameters: GetRequestParameters = {},
    ): Promise<Team[]> {
      return (await userService.getTeams(getTeamsRequestParameters)).results
        .team;
    },

    async loadCounters(): Promise<void> {
      const { numberOfTeams, numberOfManagers, numberOfPlayers } =
        await userService.getCounters();
      this.counters.numberOfPlayers = numberOfPlayers;
      this.counters.numberOfTeams = numberOfTeams;
      this.counters.numberOfManagers = numberOfManagers;
    },

    async getSimplifiedTeams(
      onlyTeamsWithOperators = false,
      disableFilter = false,
    ): Promise<void> {
      try {
        this.simplifiedTeams = (
          await userService.getSimplifiedTeams(
            onlyTeamsWithOperators,
            disableFilter,
          )
        ).results.team;
      } catch (error) {
        this.simplifiedTeams = [];
      }
    },

    async getOperators(): Promise<void> {
      try {
        this.simplifiedOperators = (
          await userService.getOperators()
        ).results.users;
      } catch (error) {
        this.simplifiedOperators = [];
      }
    },

    async validateUsers(users: NuvoUserRow[], useDefaultRoleLevel = true) {
      this.validationErrors = [];
      try {
        await userService.validateUsers(users, useDefaultRoleLevel);
        this.validationErrors = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        this.validationErrors = error.response.data.errors;
      }
    },

    async editUser(user: EditUserRequest, userReference: string) {
      await userService.editUser(user, userReference);
    },

    editTeam(editedTeam: EditTeamRequest, teamReference: string) {
      return userService.editTeam(editedTeam, teamReference);
    },

    deleteUsers(userReferences: string[]) {
      return userService.deleteUsers(userReferences);
    },

    changePassword(password: string) {
      return userService.changePassword(password, this.userEmail, this.otp);
    },

    setUserEmail(email: string): void {
      this.userEmail = email;
    },

    recoverPassword(): Promise<AxiosResponse> {
      return userService.recoverPassword(this.userEmail);
    },

    verifyCode(otp: string): Promise<AxiosResponse> {
      this.otp = otp;
      return userService.verifyCode(this.userEmail, this.otp);
    },

    reInvite(userReferences: string) {
      return userService.reInvite(userReferences);
    },

    async loadAllOperators() {
      try {
        this.operators = (
          await userService.getAllOperators()
        ).results.operators;
      } catch (error) {
        this.operators = [];
      }
    },

    async createTeam(team: NewTeam): Promise<void> {
      return userService.createTeam(team);
    },

    async createUser(requestBody: CreateUserRequestBody): Promise<void> {
      return userService.createUser(requestBody);
    },

    async deleteTeam(teamReference: string): Promise<void> {
      return userService.deleteTeam(teamReference);
    },

    async loadOngoingChallengeNumber(teamReference: string): Promise<void> {
      this.ongoingChallengeNumber = (
        await userService.getOnGoingTeamChallenges(teamReference)
      ).ongoingChallengeNumber;
    },

    async changeTeam(request: ChangeTeamRequest): Promise<void> {
      return userService.changeTeam(request);
    },

    async getOnGoingUserChallenges(userRefs: string[] = []): Promise<void> {
      try {
        this.onGoingUserChallenges = (
          await userService.getOnGoingUserChallenges(userRefs)
        ).numberUsersOngoingChallenges;
      } catch {
        this.onGoingUserChallenges = 0;
      }
    },

    async getOnGoingUserSurveys(userRefs: string[] = []): Promise<void> {
      try {
        this.onGoingUserSurveys = (
          await userService.getOnGoingUserSurveys(userRefs)
        ).numberUsersLiveSurveys;
      } catch {
        this.onGoingUserSurveys = 0;
      }
    },

    async changeSite(request: ChangeSiteRequest): Promise<void> {
      return userService.changeSite(request);
    },

    async loadAllValidOperators(metricTypeReference?: string): Promise<void> {
      try {
        this.groupedOperators = await userService.getAllValidOperators(
          metricTypeReference,
        );
      } catch {
        this.groupedOperators = undefined;
      }
    },

    async getUserQrCode(userReferece: string): Promise<ArrayBuffer> {
      return userService.getUserQrCode(userReferece);
    },

    async getTimezoneUsersParticipants(
      request: RequestUsersTimezones,
    ): Promise<boolean> {
      let differentTimezone = false;
      try {
        differentTimezone = (
          await userService.getTimezoneUsersParticipants(request)
        ).differentTimezones;
      } catch (error) {
        return false;
      }
      this.hasUsersDifferentTimezone = differentTimezone;
      return differentTimezone;
    },

    async getResultForecastUsers(
      request: RequestUsersTimezones,
    ): Promise<number | undefined> {
      try {
        return (await userService.getResultsForecast(request)).date;
      } catch (error) {
        return undefined;
      }
    },

    async getUsersSites(filterBy?: string): Promise<void> {
      this.isLoadingUsersSites = true;
      try {
        this.usersSites = (await userService.getUsersSites(filterBy)).results;
        this.isLoadingUsersSites = false;
      } catch (error) {
        this.usersSites = [];
        this.isLoadingUsersSites = false;
      }
    },

    deleteManager(): Promise<{ message: string }> {
      return userService.deleteManager();
    },

    async verifyPassword(password: string): Promise<AxiosResponse> {
      return userService.verifyPassword(password);
    },

    setLoadUsersRequest(request: LoadUsersRequest): void {
      this.loadUsersRequest = request;
    },

    setLoadManagersRequest(request: LoadManagersRequest): void {
      this.loadManagersRequest = request;
    },

    async loadManagerTeamsDetail(
      managerReference: string,
      managerDetailParameters: PaginationParameters = {},
    ): Promise<ManagerDetailTeamsDetail> {
      try {
        return await userService.getManagerTeamsDetail(
          managerReference,
          managerDetailParameters,
        );
      } catch (e) {
        return {} as ManagerDetailTeamsDetail;
      }
    },

    async loadManagerSitesDetail(
      managerReference: string,
      managerDetailParameters: PaginationParameters = {},
    ): Promise<ManagerDetailTeamsDetail> {
      try {
        return await userService.getManagerSitesDetail(
          managerReference,
          managerDetailParameters,
        );
      } catch (e) {
        return {} as ManagerDetailTeamsDetail;
      }
    },

    async loadManagerTeamsProfile(managerReference: string) {
      try {
        this.managerTeamProfile = await userService.getManagerTeamsDetail(
          managerReference,
          {
            page: 1,
            size: INFINITE_SCROLL_MODAL_PAGE_SIZE,
          },
        );
      } catch (e) {
        this.managerTeamProfile = {} as ManagerDetailTeamsDetail;
      }
    },

    async loadManagerSitesProfile(managerReference: string) {
      try {
        this.managerSiteProfile = await userService.getManagerSitesDetail(
          managerReference,
          {
            page: 1,
            size: INFINITE_SCROLL_MODAL_PAGE_SIZE,
          },
        );
      } catch (e) {
        this.managerSiteProfile = {} as ManagerDetailTeamsDetail;
      }
    },

    async changeRoleLevelBulk(
      request: ChangeRoleLevelBulkRequest,
    ): Promise<void> {
      return userService.changeRoleLevelBulk(request);
    },

    async getManagerEditDetails(
      managerReference?: string,
    ): Promise<ManagedItemsResponse> {
      return userService.getManagerEditDetails(managerReference);
    },
  },
});
