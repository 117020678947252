import { defineStore } from 'pinia';
import {
  NewSurveyRequest,
  Survey,
  SurveyQuestion,
  EditSurveyRequest,
  SurveyData,
  LoadSurveysRequest,
} from '@/shared/types/surveys';
import surveysService from '@/shared/services/surveysService/surveysService';
import { ProcessCompletionDetails } from '@/shared/types/generic';

interface State {
  surveys: Survey[];
  surveyRecipients: number;
  loadSurveysRequest: LoadSurveysRequest;
  surveyCount: {
    numberOfSurveys: number;
    numberOfScheduled: number;
    numberOfLive: number;
    numberOfCompleted: number;
    numberOfFilteredSurveys: number;
  };
}

export default defineStore({
  id: 'surveys',

  state: (): State => ({
    surveys: [],
    surveyRecipients: 0,
    loadSurveysRequest: {} as LoadSurveysRequest,
    surveyCount: {
      numberOfSurveys: 0,
      numberOfScheduled: 0,
      numberOfLive: 0,
      numberOfCompleted: 0,
      numberOfFilteredSurveys: 0,
    },
  }),

  actions: {
    async loadSurveys(): Promise<void> {
      try {
        const response = await surveysService.getSurveys(
          this.loadSurveysRequest,
        );
        this.surveys = response.results.surveyResponses;
        this.surveyCount = {
          numberOfSurveys: response.results.numberOfSurveys,
          numberOfFilteredSurveys: response.results.numberOfFilteredSurveys,
          numberOfScheduled: response.results.numberOfScheduled,
          numberOfLive: response.results.numberOfLive,
          numberOfCompleted: response.results.numberOfCompleted,
        };
      } catch (error) {
        this.surveys = [];
      }
    },

    createSurvey(newSurvey: NewSurveyRequest): Promise<void> {
      return surveysService.createSurvey(newSurvey);
    },

    async getSurveyAudience(): Promise<void> {
      try {
        this.surveyRecipients = (
          await surveysService.getSurveyAudience()
        ).numberOfUsers;
      } catch (error) {
        this.surveyRecipients = 0;
      }
    },

    async loadSurveyDetails(reference: string): Promise<void> {
      const surveyIndex = this.surveys.findIndex(
        (survey) => survey.reference === reference,
      );

      const promises: (
        | Promise<{ surveyQuestions: SurveyData }>
        | Promise<ProcessCompletionDetails>
      )[] = [surveysService.getSurveyQuestions(reference)];
      if (this.surveys[surveyIndex]?.forcedCompleted) {
        promises.push(surveysService.getSurveyCompletionDetails(reference));
      }

      const details = await Promise.all(promises);

      const { surveyQuestions } = details[0] as { surveyQuestions: SurveyData };

      this.surveys[surveyIndex] = {
        ...this.surveys[surveyIndex],
        defaultQuestions: surveyQuestions.surveyDefaults || [],
        customQuestions: surveyQuestions.surveyCustoms || [],
        numberOfRespondents: surveyQuestions.numberOfRespondents,
        completionDetails: details[1] as ProcessCompletionDetails,
      };
    },

    updatePopupFlag(popupFlag: boolean): Promise<void> {
      return surveysService.updatePopupFlag(popupFlag);
    },

    editSurvey(editSurvey: EditSurveyRequest): Promise<void> {
      return surveysService.editSurvey(editSurvey);
    },

    async getDefaultQuestions(): Promise<SurveyQuestion[]> {
      return (await surveysService.getDefaultQuestions()).results;
    },

    removeSurvey(reference: string): Promise<void> {
      return surveysService.removeSurvey(reference);
    },

    setLoadSurveysRequest(request: LoadSurveysRequest): void {
      this.loadSurveysRequest = request;
    },
  },
});
