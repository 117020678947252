import { defineStore } from 'pinia';
import {
  EditChallengeRequest,
  PositionChallengeDistribution,
  SchedulerChallengeConfig,
  ChallengeModeStaticReference,
  ChallengeGoal,
  ChallengeStatus,
  ChallengeStatusId,
  ChallengeTypeStaticReference,
  ChallengeGoalStaticReference,
  RecurrencyOption,
  ChallengeLeaderboardResponse,
  GameDetails,
  LoadGameRequest,
  ChallengeAudienceStaticReference,
  CreateChallengeEnabled,
  ChallengeParticipantsDetail,
  ChallengeParticipantsTeamsDetail,
  Participants,
  ChallengeLeaderboardUser,
  NewChallengeRequest,
} from '@/shared/types/challenges';
import {
  ChallengeFormatOptions,
  ChallengeForm,
  ChallengeFormat,
  ChallengeModeType,
  ChallengeImage,
  IChallenge,
} from '@/shared/types/wizard';
import challengesService from '@/shared/services/challengesService/challengesService';
import { ApiResponse, ProcessCompletionDetails } from '@/shared/types/generic';
import { ResponseError } from '@/shared/types/utils';
import { GetRequestParameters } from '@/shared/types/requests';

interface Format {
  mode: ChallengeModeStaticReference;
  type: ChallengeTypeStaticReference;
  audience: ChallengeAudienceStaticReference;
}

interface ChallengeModeTypeReference extends ChallengeModeType {
  audience: ChallengeAudienceStaticReference;
}

export default defineStore({
  id: 'challenges',

  state: () => ({
    challengeSchedulerConfig: [] as SchedulerChallengeConfig[],
    loadGameRequest: {} as LoadGameRequest,
    challengeCount: {
      numberOfChallenges: 0,
      numberOfFilteredChallenges: 0,
      numberOfFinished: 0,
      numberOfOnGoing: 0,
      numberOfScheduled: 0,
      numberOfCurrent: 0,
      numberOfClosed: 0,
    },
    gameLeaderboard: {} as ApiResponse<ChallengeLeaderboardResponse>,
    distributionPoints: {} as PositionChallengeDistribution,
    distributionCoins: {} as PositionChallengeDistribution,
    gameGoals: [] as ChallengeGoal[],
    recurrencyConfigs: [] as RecurrencyOption[],
    recurrentPointsMultiplier: 0,
    challengeFormats: [] as ChallengeFormatOptions[],
    challengeWizardForm: {} as ChallengeForm,
    challengeImage: [] as ChallengeImage[],
    wizardFormError: 0,
    isEnabledCreateChallenge: {} as CreateChallengeEnabled,
    challenges: [] as IChallenge[],
    participantsDetail: {} as ChallengeParticipantsDetail,
    teamsParticipantsPaginated: [] as ChallengeParticipantsTeamsDetail[],
    participantsPaginated: [] as Participants[],
    gameLeaderboardPaginated: [] as ChallengeLeaderboardUser[],
    duplicatedChallenge: {} as IChallenge,
  }),

  getters: {
    getFormatCorrespondenceModeType(state) {
      const formatsByAudience = state.challengeFormats.reduce(
        (
          acc: ChallengeModeTypeReference[],
          audience: ChallengeFormatOptions,
        ) => {
          acc.push(
            ...Object.values(
              audience.challengeModeTypes.map((modeType: ChallengeModeType) => {
                return {
                  ...modeType,
                  audience: audience.audience
                    .staticReference as ChallengeAudienceStaticReference,
                };
              }),
            ),
          );
          return acc;
        },
        [] as ChallengeModeTypeReference[],
      );

      return formatsByAudience.reduce(
        (
          acc: Record<ChallengeFormat, Format>,
          format: ChallengeModeTypeReference,
        ) => {
          acc[format.staticReference as ChallengeFormat] = {
            mode: format.challengeMode.staticReference,
            type: format.challengeType.staticReference,
            audience: format.audience,
          };
          return acc;
        },
        {} as Record<ChallengeFormat, Format>,
      );
    },
  },

  actions: {
    getStatusByStatusId(statusId: ChallengeStatusId): ChallengeStatus[] {
      switch (statusId) {
        case ChallengeStatusId.SCHEDULED:
          return [ChallengeStatus.NOT_STARTED, ChallengeStatus.READY];
        case ChallengeStatusId.ON_GOING:
          return [ChallengeStatus.ON_GOING];
        case ChallengeStatusId.FINISHED:
          return [
            ChallengeStatus.CALCULATING_RESULTS,
            ChallengeStatus.COMPLETED,
            ChallengeStatus.DONE,
            ChallengeStatus.CANCELED,
          ];
        default:
          return Object.values(ChallengeStatus);
      }
    },

    getChallengeStatusByStatusId(
      statusId: ChallengeStatusId,
    ): ChallengeStatus[] {
      switch (statusId) {
        case ChallengeStatusId.SCHEDULED:
          return [ChallengeStatus.NOT_STARTED, ChallengeStatus.READY];
        case ChallengeStatusId.ON_GOING:
          return [
            ChallengeStatus.ON_GOING,
            ChallengeStatus.CALCULATING_RESULTS,
          ];
        case ChallengeStatusId.FINISHED:
          return [
            ChallengeStatus.COMPLETED,
            ChallengeStatus.DONE,
            ChallengeStatus.CANCELED,
          ];
        default:
          return Object.values(ChallengeStatus);
      }
    },

    setLoadChallengeRequest(request: LoadGameRequest): void {
      this.loadGameRequest = request;
    },

    resetChallenges(): void {
      this.challenges = [];
    },

    removeChallenge(reference: string): Promise<{ message: string }> {
      return challengesService.removeChallenge(reference);
    },

    finishChallenge(reference: string, motive?: string): Promise<void> {
      return challengesService.finishChallenge(reference, motive);
    },

    cancelChallenge(reference: string, motive: string): Promise<void> {
      return challengesService.cancelChallenge(reference, motive);
    },

    createChallenge(newGame: NewChallengeRequest): Promise<void> {
      return challengesService.createChallenge(newGame);
    },

    editChallenge(
      editedGame: EditChallengeRequest,
      gameReference: string,
    ): Promise<{ message: string }> {
      return challengesService.editChallenge(editedGame, gameReference);
    },

    async getChallengeSchedulerConfig(): Promise<void> {
      try {
        this.challengeSchedulerConfig = (
          await challengesService.getSchedulerChallengeConfig()
        ).results;
      } catch (e) {
        this.challengeSchedulerConfig = [];
      }
    },

    async loadChallengeLeaderboard(
      reference: string,
      leaderboardDetailParams: GetRequestParameters = {},
    ): Promise<void> {
      try {
        this.gameLeaderboard = await challengesService.getLeaderboardDetail(
          reference,
          leaderboardDetailParams,
        );
      } catch (e) {
        this.gameLeaderboard = {} as ApiResponse<ChallengeLeaderboardResponse>;
      }
    },

    async loadChallengeGoals(
      typeReference: ChallengeTypeStaticReference,
    ): Promise<void> {
      try {
        this.gameGoals = (
          await challengesService.getChallengeGoals(typeReference)
        ).results.challengeGoals;
      } catch (e) {
        this.gameGoals = [];
      }
    },

    async loadChallengeDistributionCoins(
      totalCoins: number,
      challengeModeStaticReference: ChallengeModeStaticReference,
      challengeTypeStaticReference?: ChallengeTypeStaticReference,
      totalPlayers?: number,
    ): Promise<void> {
      try {
        this.distributionCoins =
          await challengesService.getChallengeDistributionCoins(
            totalCoins,
            challengeModeStaticReference,
            challengeTypeStaticReference,
            totalPlayers,
          );
      } catch (e) {
        this.distributionCoins = {} as PositionChallengeDistribution;
      }
    },

    async loadChallengeDistributionPoints(
      duration: number,
      challengeModeStaticReference: ChallengeModeStaticReference,
      challengeTypeStaticReference?: ChallengeTypeStaticReference,
      totalPlayers?: number,
    ): Promise<void> {
      try {
        this.distributionPoints =
          await challengesService.getChallengeDistributionPoints(
            duration,
            challengeModeStaticReference,
            challengeTypeStaticReference,
            totalPlayers,
          );
      } catch (e) {
        this.distributionPoints = {} as PositionChallengeDistribution;
      }
    },

    async loadDuplicatedChallenge(challengeReference: string): Promise<void> {
      this.duplicatedChallenge = await challengesService.getDuplicatedChallenge(
        challengeReference,
      );
    },

    getGoalTranslation(
      translate: (
        key: string,
        parameters: (string | number)[] | (string | number),
      ) => string,
      game: {
        typeStaticReference: ChallengeTypeStaticReference;
        goalStaticReference?: ChallengeGoalStaticReference;
        unitMeasureName: string;
        targetAmount?: number;
      },
    ): string {
      const pluralHandler =
        game.typeStaticReference === ChallengeTypeStaticReference.ACHIEVER
          ? 1
          : 0;

      const staticReference =
        game.typeStaticReference === ChallengeTypeStaticReference.CLASSIC
          ? game.goalStaticReference
          : game.typeStaticReference;

      return translate(`games.goalDescription.${staticReference}`, [
        game.targetAmount || pluralHandler,
        translate(
          `enums.${game.unitMeasureName}`,
          game.targetAmount || pluralHandler,
        ),
      ]);
    },

    async loadRecurrencyConfigs(duration: number): Promise<void> {
      try {
        this.recurrencyConfigs = (
          await challengesService.getRecurrencyIntervals(duration)
        ).results.recurrencies;
      } catch (e) {
        this.recurrencyConfigs = [];
      }
    },

    async loadChallengeFormats(): Promise<void> {
      try {
        this.challengeFormats = await challengesService.getChallengeFormats();
      } catch (e) {
        this.challengeFormats = [];
      }
    },

    async loadChallengeImage(
      challengeModeStaticReference: string,
      challengeTypeStaticReference: string,
      challengeGoalStaticReference?: string,
    ): Promise<void> {
      try {
        this.challengeImage = (
          await challengesService.getChallengeImage(
            challengeModeStaticReference,
            challengeTypeStaticReference,
            challengeGoalStaticReference,
          )
        ).results;
      } catch (e) {
        this.challengeImage = [] as ChallengeImage[];
      }
    },

    async createWizardChallenge(challenge: NewChallengeRequest) {
      this.wizardFormError = 0;
      try {
        await challengesService.createChallenge(challenge);
      } catch (e) {
        if (e instanceof ResponseError) {
          this.wizardFormError = e.reason.code;
        }
        throw e;
      }
    },

    async getCreationChallengeEnabled(): Promise<void> {
      try {
        this.isEnabledCreateChallenge =
          await challengesService.getCreationChallengeEnabled();
      } catch (e) {
        this.isEnabledCreateChallenge = {
          challengeCreationEnabled: false,
          hasMetrics: false,
          hasWorkers: false,
        };
      }
    },

    async loadChallenges(): Promise<void> {
      try {
        const response = await challengesService.getChallenges(
          this.loadGameRequest,
        );
        this.challenges = response.results.challenges;
        this.challengeCount = {
          numberOfChallenges: response.results.numberOfChallenges,
          numberOfFilteredChallenges:
            response.results.numberOfFilteredChallenges,
          numberOfFinished: response.results.numberOfFinished,
          numberOfOnGoing: response.results.numberOfOnGoing,
          numberOfScheduled: response.results.numberOfScheduled,
          numberOfClosed: response.results.numberOfClosed,
          numberOfCurrent: response.results.numberOfCurrent,
        };
      } catch (error) {
        this.challenges = [];
      }
    },

    async loadChallengeDetails(challengeReference: string): Promise<void> {
      const gameIndex = this.challenges.findIndex(
        (game) => game.reference === challengeReference,
      );

      const promises: (
        | Promise<GameDetails>
        | Promise<ProcessCompletionDetails>
      )[] = [challengesService.getChallengeDetails(challengeReference)];
      if (
        this.challenges[gameIndex]?.statusStaticReference ===
          ChallengeStatus.CANCELED ||
        this.challenges[gameIndex]?.forcedCompleted
      ) {
        promises.push(
          challengesService.getChallengeCompletionDetails(challengeReference),
        );
      }

      const details = await Promise.all(promises);

      this.challenges[gameIndex] = {
        ...this.challenges[gameIndex],
        ...details[0],
        completionDetails: details[1] as ProcessCompletionDetails,
      };
    },

    async loadParticipantDetail(
      challengeReference: string,
      participantsDetailParameters: GetRequestParameters = {},
    ): Promise<ChallengeParticipantsDetail> {
      try {
        return await challengesService.getParticipantsDetail(
          challengeReference,
          participantsDetailParameters,
        );
      } catch (e) {
        return {} as ChallengeParticipantsDetail;
      }
    },

    async loadLeaderBoardDetail(
      reference: string,
      leaderboardDetailParams: GetRequestParameters = {},
    ): Promise<ChallengeLeaderboardResponse> {
      try {
        return (
          await challengesService.getLeaderboardDetail(
            reference,
            leaderboardDetailParams,
          )
        ).results;
      } catch (error) {
        return {} as ChallengeLeaderboardResponse;
      }
    },
  },
});
